import axios from "axios";
import { startFetching, endFetching, switchOffline } from "../actions/app";
import { getJWTToken } from "../../auth/auth";

export default function fetchAxios(
  dispatch,
  method,
  url,
  data,
  params,
  protect,
  customToken,
  disableFetching,
) {
  return new Promise(function (resolve, reject) {
    if (navigator.onLine) {
      if (dispatch && !disableFetching) {
        dispatch(startFetching());
      }
      var headers = null;
      if (protect) {
        let token = customToken || getJWTToken();
        headers = {
          Authorization: "Bearer " + token,
        };
      }

      axios({ method, url, data, params, headers })
        .then((response) => {
          if (dispatch && !disableFetching) {
            dispatch(endFetching());
          }
          resolve(response);
        })
        .catch((e) => {
          if (dispatch && !disableFetching) {
            dispatch(endFetching());
          }
          console.log(e);
          reject();
        });
    } else {
      dispatch(switchOffline());
      reject();
    }
  });
}
