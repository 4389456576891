import {
  LIST_CATEGORIES,
  UPDATE_CATEGORY,
  SET_CATEGORY,
  SET_CATEGORIES,
  SAVE_CATEGORY,
  REMOVE_CATEGORY,
} from "../actions/categories/types";

const initialState = {
  isFetching: false,
  categories: [],
  categoryDetail: {},
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CATEGORIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        categories: action.categories || state.categories,
      };
    case REMOVE_CATEGORY:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categoryDetail: {
          ...state.categoryDetail,
          [action.field]: action.value,
        },
      };
    case SET_CATEGORY:
      return {
        ...state,
        isFetching: false,
        categoryDetail: action.categoryDetail,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        categories: action.categories,
      };
    case SAVE_CATEGORY:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default categories;
