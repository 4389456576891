import {
  LIST_FEATURES,
  UPDATE_FEATURE,
  SET_FEATURE,
  SET_FEATURES,
  SAVE_FEATURE,
  REMOVE_FEATURE,
} from "../actions/productfeatures/types";

const initialState = {
  isFetching: false,
  productFeatures: [],
  featureDetail: {},
};

const productFeatures = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FEATURES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        productFeatures: action.productFeatures || state.productFeatures,
      };
    case REMOVE_FEATURE:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_FEATURE:
      return {
        ...state,
        featureDetail: {
          ...state.featureDetail,
          [action.field]: action.value,
        },
      };
    case SET_FEATURE:
      return {
        ...state,
        isFetching: false,
        featureDetail: action.featureDetail,
      };
    case SET_FEATURES:
      return {
        ...state,
        isFetching: false,
        productFeatures: action.productFeatures,
      };
    case SAVE_FEATURE:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default productFeatures;
