import React from "react";
import { Route, Redirect } from "react-router-dom";

const isHashed = (path) => {
  return path.indexOf("#/") > -1;
};

const unHash = (path) => {
  if (path) {
    let newPath = path.replace("#/app/", "");
    newPath = newPath.replace("#/", "");
    return newPath;
  }
  return;
};

const HashedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        props.location &&
        props.location.hash &&
        isHashed(props.location.hash)
      ) {
        return <Redirect to={unHash(props.location.hash)} />;
      }
      return <Component {...props} />;
    }}
  />
);

export default HashedRoute;
