import { AUTH_USER } from "../actions/auth/types";

const initialState = {
  authenticated: false,
  user: undefined,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: true, user: action.user || undefined };
    default:
      return state;
  }
};

export default auth;
