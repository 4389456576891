export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const SET_CONTENT = "SET_CONTENT";
export const SET_CONTENTS = "SET_CONTENTS";
export const LIST_CONTENTS = "LIST_CONTENTS";
export const LOAD_CONTENT = "LOAD_CONTENT";
export const SAVE_CONTENT = "SAVE_CONTENT";
export const REMOVE_CONTENT = "REMOVE_CONTENT";
export const UPDATE_CONTENTGROUP = "UPDATE_CONTENTGROUP";
export const SET_CONTENTGROUP = "SET_CONTENTGROUP";
export const SET_CONTENTGROUPS = "SET_CONTENTGROUPS";
export const LIST_CONTENTGROUPS = "LIST_CONTENTGROUPS";
export const LOAD_CONTENTGROUP = "LOAD_CONTENTGROUP";
export const SAVE_CONTENTGROUP = "SAVE_CONTENTGROUP";
export const REMOVE_CONTENTGROUP = "REMOVE_CONTENTGROUP";
