import fetchAxios from "../../middlewares/axios";
import decode from "jwt-decode";
import { onSignIn } from "../../../auth/auth";
import { clear } from "../../../localstorage/auth";
import { AUTH_USER } from "./types";
import { ROOT_URL_ANONYMOUS } from "../../../config/axios";

export const signInUser = (email, password) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      fetchAxios(dispatch, "post", `${ROOT_URL_ANONYMOUS}/auth/signin`, {
        username: email,
        password,
        admin: true,
      })
        .then((response) => {
          if (response && response.data && response.data.token) {
            const decoded = decode(response.data.token);
            onSignIn(response.data.token);
            dispatch({
              type: AUTH_USER,
              user: decoded.user,
            });
            resolve(response);
          } else {
            reject("NOUSER");
          }
        })
        .catch((e) => {
          reject({ error: e });
        });
    });
  };
};

export const signOutUser = () => {
  return (dispatch) => {
    clear();
    dispatch(reset());
    return Promise.resolve();
  };
};

const reset = () => {
  return {
    type: "RESET_APP",
  };
};
